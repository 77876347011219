import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import 'css/custom-select-style.css';
import { MdClose } from 'react-icons/md';
import { connect } from 'react-redux';
import professionsActions from 'actions/professions';
import rolesActions from 'actions/roles';
import employeeTypesActions from 'actions/employee_types';
import employeesActions from 'actions/employees';
import store from 'store';
import Formsy from 'formsy-react';
import ImageEditor from 'components/ImageEditor';
import Input from 'components/Input';
import FormCheckBox from 'components/CheckBox';
import { FormSelect } from 'components/CustomSelect';
import NeedWritePermission from 'components/NeedPermission';
import CardInput from 'components/CardInput';
import { formatDate } from 'scripts/dateFormatter';

class RegisterEmployee extends React.Component {
	constructor(props) {
		super(props);
		this.editorRef = React.createRef();
		this.state = {
			errors: {},
			formSubmitted: false
		};

		this.mounted = false;
	}

	componentDidMount() {
		this.mounted = true;

		store.dispatch(professionsActions.fetchAll());
		store.dispatch(rolesActions.fetchAll());
		store.dispatch(employeeTypesActions.fetchAll());

		if (!this.props.employees.length) {
			store.dispatch(employeesActions.fetchAll());
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	handleFormSubmit(model) {
		this.setState({
			formSubmitted: true
		});

		if (this.validateForm(model)) {
			return;
		}

		let newObj = {
			...model,
			role_id: model.role.id,
			type_id: model.type.id,
			profession_id: model.profession.id,
			username: model.name,
			boss_id: model.boss.id,
		};

		if (!model.password) {
			delete newObj['password'];
		}

		this.editorRef.current.getBlob('photo', newObj)
			.then( obj => {
				this.props.onSubmit(obj);
			});
	}

	validatePassword(password){
		if (password.length < 8){
			return 'Heslo musí mať aspoň 8 znakov';
		}
		var regex = new RegExp('[A-Z]');
		if (!regex.test(password)){
			return 'Heslo musí obsahovať aspoň jedno veľké písmeno';
		}
		regex = new RegExp('[a-z]');
		if (!regex.test(password)){
			return 'Heslo musí obsahovať aspoň jedno malé písmeno';
		}
		regex = new RegExp('[0-9]');
		if (!regex.test(password)){
			return 'Heslo musí obsahovať aspoň jedno číslo';
		}
		return null;
	}

	validateForm(model) {
		let validations = {
			first_name: 'Zadajte meno',
			last_name: 'Zadajte priezvisko',
			type: 'Zvoľte typ',
			role: 'Zvoľte rolu',
			profession: 'Zvoľte profesiu',
		};

		let errors = {};

		Object.keys(validations).forEach( key => {
			if (!model[key]) {
				errors[key] = validations[key];
			}
		});

		if (model['password']){
			let passwdError = this.validatePassword(model['password']);
			if (passwdError){
				errors['password'] = passwdError;
			}
		}

		if (this.mounted) {
			this.setState({
				errors: errors
			});
		}

		return (Object.keys(errors).length > 0);
	}

	getDefaultValue(values, key, value){
		if (values){
			return values.find( o => o[key] === value);
		}
		return null;
	}

	render() {
		const { defaultValues: DV } = this.props;
		const {state: S} = this;
		let { roles, professions, employeeTypes, employees } = this.props;
		let defaultProfession = null;
		let defaultType = null;
		let defaultRole = null;
		let defaultMaster = null;

		if (this.props.updating && DV) {
			defaultProfession = professions.find( p => p.id === DV.profession_id);
			defaultType = employeeTypes.find( t => t.id === DV.type_id);
			defaultRole = roles.find( r => r.id === DV.role_id);
			defaultMaster = employees.find(e => e.id === DV.boss_id);
		} else {
			defaultProfession = this.getDefaultValue(professions, 'id', 18);
			defaultType = this.getDefaultValue(employeeTypes, 'id', 2);
			defaultRole = this.getDefaultValue(roles, 'id', 2);
			defaultMaster = null;
		}
		const onKeyPress = event => {
			if (event.which === 13 /* Enter */) {
				event.preventDefault();
			}
		};

		return (
			<Modal
				size="xl"
				className="employees-form"
				show
				onHide={this.props.onClose}
			>
				<Modal.Header>
					<div
						className="closeBox"
						style={{ float: 'right', height: 25}}
					>
						<MdClose
							size={24}
							onClick={ this.props.onClose }
							style={{ cursor: 'pointer' }}
						/>
					</div>
				</Modal.Header>
				<Modal.Body className={'container'}>
					<Formsy
						onKeyPress={onKeyPress}
						onValidSubmit={model => this.handleFormSubmit(model)}
						onInvalidSubmit={ model => this.handleFormSubmit(model)}
						onChange={ values => this.validateForm(values)}
						validationErrors={this.state.errors}
						autoComplete={'off'}
					>
						<Row>
							<Col lg={6} md={12}>
                                <input type="hidden" value="something"/>
								<Input
									name="first_name" 
									label="Meno *"
									value={DV.first_name} 
									//required
									isValid={ val => Boolean(val)}
									formNoValidate
									isFormSubmitted={ () => S.formSubmitted}
                                    autoComplete={'first_name'}
								/>
							</Col>
							<Col lg={6} md={12}>
								<Input
									name="last_name" 
									label="Priezvisko *"
									value={DV.last_name}
									isValid={ val => Boolean(val)}
									//required
									formNoValidate
									isFormSubmitted={ () => S.formSubmitted}
                                    autoComplete={'last_name'}
								/>
							</Col>
						</Row>
						<Row>
							<Col lg={6} md={12}>
								<Input 
									name="abbreviation" 
									label="Skratka" 
									value={DV.abbreviation || ''}
									autoComplete={'abbreviation'}
								/>
							</Col>
							<Col lg={6} md={12}>
                                <CardInput
                                    name="curr_card"
                                    label="Číslo karty"
                                    value={DV.curr_card ? DV.curr_card : ''}
                                />
							</Col>
						</Row>
						{
							this.props.gdpr ? <div>
							<Row>
								<Col lg={6} md={12}>
									<Input
										name="email"
										label="E-mail"
										value={DV.email ? DV.email : ''}
										validations="isEmail"
										autoComplete={'email'}
									/>
								</Col>
								<Col lg={6} md={12}>
									<Input
										name="work_email"
										label="Pracovný mail"
										value={DV.work_email ? DV.work_email : ''}
										validations="isEmail"
										autoComplete={'email'}
									/>
								</Col>
							</Row>
							<Row>
								<Col lg={6} md={12}>
									<Input
										name="mobile_number"
										label="Telefónne číslo"
										value={DV.mobile_number ? DV.mobile_number : ''}
										autoComplete={'mobile_number'}
									/>
								</Col>
								<Col lg={6} md={12}>
									<Input
										name="work_phone"
										label="Pracovný telefón"
										value={DV.work_phone ? DV.work_phone : ''}
										autoComplete={'mobile_number'}
									/>
								</Col>
							</Row>
								<Row>
									<Col lg={6} md={12}>
										<Input
											name="address"
											label="Adresa"
											value={DV.address || ''}
											autoComplete={'address'}
										/>
									</Col>
									<Col lg={6} md={12}>
										<Input
											type="date"
											name="birthday"
											label="Dátum narodenia"
											value={formatDate(DV.birthday)}
										/>
									</Col>
								</Row>
							</div> : null
						}
						<Row>
							<Col lg={6} md={12}>
								<FormSelect
									name={'profession'}
									label={'Profesia'}
									options={professions}
									getOptionValue={ p => p.id}
									getOptionLabel={ p => p.name}
									value={defaultProfession}
									required
									isClearable
									isFormSubmitted={ () => S.formSubmitted}
								/>
							</Col>
							<Col lg={6} md={12}>
								<FormSelect
									name={'role'}
									label={'Rola'}
									options={roles}
									getOptionValue={ r => r.id}
									getOptionLabel={ r => r.name}
									value={defaultRole}
									required
									isClearable
									isFormSubmitted={ () => S.formSubmitted}
								/>
							</Col>
						</Row>
						<Row>
							<Col lg={6} md={12}>
								<Input
									name="name"
									label="Prihlasovacie meno"
									value={DV.username ? DV.username : ''}
                                    autoComplete={'nope'}
								/>
							</Col>
							<Col lg={6} md={12}>
								<Input
									type="password"
									name="password"
									label="Heslo"
                                    autoComplete={'new-password'}
								/>
							</Col>
						</Row>
						<Row>
							<Col lg={6} md={12}>
								<FormSelect
									name={'type'}
									label={'Typ'}
									options={employeeTypes}
									getOptionValue={ t => t.id}
									getOptionLabel={ t => t.name}
									value={defaultType}
									required
									isClearable
									isFormSubmitted={ () => S.formSubmitted}
								/>
							</Col>
							<Col lg={6} md={12}>
								<Input
									name="comment"
									label="Komentár"
									value={DV.comment || ''}
								/>
							</Col>
						</Row>
						<Row>
							<Col lg={6} md={12}>
								<FormSelect
									name={'boss'}
									label={'Aktuálny majster'}
									options={employees}
									getOptionValue={ t => t.id}
									getOptionLabel={ t => t.full_name}
									value={defaultMaster}
									required={false}
									isClearable={true}
									isFormSubmitted={ () => S.formSubmitted}
								/>
							</Col>
							<Col lg={6} md={12}>
								<FormCheckBox
									name="active"
									label="Aktívny"
									value={DV.active}
									id={'active'}
								/>
							</Col>
						</Row>
						<Row>
							<Col lg={6} md={12}>
								<Row className={'form-group form-row'}>
									<label className="col-form-label col-sm-4">Fotka</label>
									<Col sm={8}>
										<ImageEditor
											ref={this.editorRef}
											photo={DV.photo}
											isZoomable={true}
											size={100}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row>
							<Col
								lg={6} md={12}
								style={{ marginTop: 25 }}
							>
								<Row>
									<Col sm={{span: 8, offset: 4}}>
										<NeedWritePermission type="button" need={['employees']}>
											<Button
												variant="success"
												type="submit"
											>
												{ this.props.updating ? 'Upraviť' : 'Vytvoriť' }
											</Button>
										</NeedWritePermission>
									</Col>
								</Row>
							</Col>
							<Col lg={6} md={12}/>
						</Row>
					</Formsy>
				</Modal.Body>
			</Modal>
		);
	}
}

RegisterEmployee.propTypes = {
	updating: PropTypes.bool.isRequired,
	defaultValues: PropTypes.object.isRequired,
	onClose: PropTypes.func,
	gdpr: PropTypes.bool.isRequired,
	employees: PropTypes.array.isRequired
};

RegisterEmployee.defaultProps = {
	defaultValues: {
		photo: null,
		active: true,
		boss_id: null,
	},
	gdpr: false
};

const mapStateToProps = (state) => {
	return {
		roles: state.roles.items,
		professions: state.professions.items,
		employeeTypes: state.employeeTypes.items,
		employees: state.employees.items.filter( e => (e.active && e.role.name === 'Majster'))
			.sort( (a, b) => a.full_name.localeCompare(b.full_name))
	};
};

export default connect(mapStateToProps)(RegisterEmployee);
